import { ISong } from '@/lib/contentful/interfaces/page'
import { Button, Link } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { join } from 'lodash'
import { FC, useEffect, useCallback } from 'react'
import OptionalIcon from '../action-button/optional-icon'
import { kebabCase } from 'lodash'
import {
    retrieveImageUrlFromMediaWrapper,
    transformBaseButtonToLink
} from '@/lib/contentful/helpers/common'
import SongLyrics from './song-lyrics'

import { usePage } from '@inertiajs/react'
import ReactGA from 'react-ga4'

const convertToEmbedUrl = (url: string): string => {
    try {
        const urlObj = new URL(url)
        if (
            urlObj.hostname.includes('youtube.com') &&
            urlObj.pathname === '/watch'
        ) {
            return `https://www.youtube.com/embed/${urlObj.searchParams.get(
                'v'
            )}`
        }
        if (urlObj.hostname.includes('youtu.be')) {
            return `https://www.youtube.com/embed${urlObj.pathname}`
        }
        return url
    } catch {
        return url
    }
}

// ReactGA.initialize("G-XXXXXXXXXX");

// Facebook Pixel Tracking (Replace with your Pixel ID)
const trackFacebookPixel = (
    eventName: string,
    data: Record<string, unknown> = {}
) => {
    if (window.fbq) {
        window.fbq('track', eventName, data)
    }
}

const SongSmartLink: FC<ISong> = song => {
    const { url } = usePage()
    // Animation variants
    const containerVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    }

    const buttonVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: (i: number) => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.1, duration: 0.5 }
        })
    }

    useEffect(() => {
        // Track Page View
        ReactGA.send({
            hitType: 'pageview',
            page: url,
            title: song.fields.title
        })
        trackFacebookPixel('SmartlinkVisitEvent', {
            event_category: 'Smartlink',
            event_action: 'smartlinkVisit',
            content_name: song.fields.title,
            content_category: 'Music',
            page: url
        })
    }, [url, song])

    // Handles streaming link clicks
    const handleLinkClick = useCallback(
        (platform: string, link: string) => {
            ReactGA.event({
                category: 'Streaming',
                action: 'Click',
                label: kebabCase(platform)
            })
            trackFacebookPixel('SmartlinkEvent', {
                event_category: 'Smartlink',
                event_action: 'streamingServiceClick',
                content_name: song.fields.title,
                content_category: 'Music',
                platform: kebabCase(platform),
                link
            })
        },
        [song]
    )
    const youtubeSnippetUrl = song?.fields?.youtubeVideoSnippet?.fields?.url
    const youtubeOfficialVideoUrl =
        song?.fields?.officialYoutubeVideo?.fields?.url

    const coverArtUrl = retrieveImageUrlFromMediaWrapper(song?.fields?.coverArt)

    return (
        <div className=' bg-primary-50/60 flex flex-col gap-4 p-2 w-full'>
            <div className='flex flex-col gap-1 max-w-screen-md w-full m-auto h-full'>
                <div className='h-full'>
                    {youtubeSnippetUrl ? (
                        <div
                            className='relative h-0 overflow-hidden max-w-full w-full'
                            style={{ paddingBottom: '56.25%' }}
                        >
                            <iframe
                                src={convertToEmbedUrl(youtubeSnippetUrl)}
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                                className='absolute top-0 left-0 w-full h-full'
                            ></iframe>
                        </div>
                    ) : youtubeOfficialVideoUrl ? (
                        <div
                            className='relative h-0 overflow-hidden max-w-full w-full'
                            style={{ paddingBottom: '56.25%' }}
                        >
                            <iframe
                                src={convertToEmbedUrl(youtubeOfficialVideoUrl)}
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                                className='absolute top-0 left-0 w-full h-full'
                            ></iframe>
                        </div>
                    ) : (
                        <div
                            className='relative h-0 overflow-hidden max-w-full w-full bg-black'
                            style={{ paddingBottom: '56.25%' }}
                        >
                            <img
                                src={coverArtUrl?.url}
                                alt={coverArtUrl?.alt}
                                className='absolute top-0 left-0 w-full h-full object-contain'
                            />
                        </div>
                    )}
                </div>

                <div className='h-[20%] text-center border-top-2 bg-primary-50/80 w-full py-2 '>
                    <h4 className=''>{song?.fields?.title}</h4>
                    <p className='text-sm mt-1'>
                        {join(song?.fields?.composers, ', ')}
                    </p>
                </div>
                <div className='py-4 px-2'>
                    <motion.div
                        initial='hidden'
                        animate='visible'
                        variants={containerVariants}
                        className='flex flex-col gap-4'
                    >
                        {song?.fields?.streamingLinks?.map((btn, snglnkx) => {
                            return (
                                <Button
                                    key={`key-${snglnkx}`}
                                    as={Link}
                                    href={btn?.fields?.url}
                                    target={'_blank'}
                                    className='flex gap-4 justify-between py-6'
                                    onClick={() =>
                                        handleLinkClick(
                                            btn?.fields?.optionalIcon ||
                                                'not-specified',
                                            btn?.fields?.url
                                        )
                                    }
                                >
                                    <Button
                                        as={Link}
                                        href={btn?.fields?.url}
                                        isIconOnly={
                                            btn?.fields?.optionalIcon
                                                ? true
                                                : false
                                        }
                                        target={'_blank'}
                                        className='p-1'
                                    >
                                        {btn?.fields?.optionalIcon ? (
                                            <OptionalIcon
                                                name={btn?.fields?.optionalIcon}
                                            />
                                        ) : (
                                            <>{btn?.fields?.title}</>
                                        )}
                                    </Button>

                                    <div className=''>{btn?.fields?.title}</div>
                                </Button>
                            )
                        })}
                    </motion.div>
                </div>

                {/* <div className=''>{song?.fields?.description}</div> */}
                <div className='py-10 flex flex-col gap-4'>
                    <hr className='' />
                    <p className=''>Song Credits</p>
                    {song?.fields?.recordingStudio && (
                        <p className='text-sm'>
                            Recorded at <b>{song?.fields?.recordingStudio}</b>
                        </p>
                    )}
                    {song?.fields?.recordingEngineers && (
                        <p className='text-sm'>
                            Engineer(s){' '}
                            <b>
                                {join(song?.fields?.recordingEngineers, ', ')}
                            </b>
                        </p>
                    )}
                    {song?.fields?.mixingMasteringEngineers && (
                        <p className='text-sm'>
                            Mixing & Mastering(s){' '}
                            <b>
                                {join(
                                    song?.fields?.mixingMasteringEngineers,
                                    ', '
                                )}
                            </b>
                        </p>
                    )}

                    {song?.fields?.lyrics && (
                        <SongLyrics lyricsData={song?.fields?.lyrics} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default SongSmartLink
