import { useState, useEffect, useRef } from 'react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { BLOCKS, Document } from '@contentful/rich-text-types'

interface IKaraokeLyricsProps {
    lyricsData: any
}

const SongLyrics: React.FC<IKaraokeLyricsProps> = ({ lyricsData }) => {
    const [currentLine, setCurrentLine] = useState<number>(0)
    const lyricsRef = useRef<HTMLDivElement>(null)
    // const lyricsArray: string[] = documentToPlainTextString(lyricsData)
    //     .split('\n') // Split by line breaks
    //     .filter(line => line.trim() !== '')

    const lyricsArray = lyricsData?.split('\n')

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentLine(prev =>
                prev < lyricsArray.length - 1 ? prev + 1 : prev
            )
        }, 2000) // Change line every 2 seconds

        return () => clearInterval(interval)
    }, [])

    // Auto-scroll to keep the highlighted line in view
    // useEffect(() => {
    //     if (lyricsRef.current) {
    //         const activeLine = lyricsRef.current.querySelector('.highlighted')
    //         if (activeLine) {
    //             activeLine.scrollIntoView({
    //                 behavior: 'smooth',
    //                 block: 'center'
    //             })
    //         }
    //     }
    // }, [currentLine])

    return (
        <div className='max-w-2xlx mx-autox p-6 bg-gray-900 text-white rounded-lg shadow-lg font-nunito'>
            <h2 className='text-2xl font-bold text-center mb-4'>Lyrics</h2>
            <div
                ref={lyricsRef}
                className='text-lg leading-relaxed text-center max-h-80 overflow-y-auto p-4 bg-gray-800 rounded-lg'
            >
                {lyricsArray.map((line: any, index: number) => (
                    <p
                        key={index}
                        className={`mb-2 transition-all duration-300 ${
                            index === currentLine
                                ? 'text-yellow-400 font-bold highlighted'
                                : 'text-gray-400'
                        }`}
                    >
                        {line}
                    </p>
                ))}
            </div>
        </div>
    )
}

export default SongLyrics
