import SampleComponent from "@/components/contentful/sample-component/sample-component";
import HeroBannerWrapper from "@/components/contentful/hero-banner-wrapper/hero-banner-wrapper";
import CtaWrapper from "@/components/contentful/cta-wrapper/cta-wrapper";
import RichContentBlockWrapper from "@/components/contentful/rich-content-block-wrapper/rich-content-block-wrapper";
import PexelsMediaWrapper from "@/components/contentful/pexels-image-wrapper/pexels-image-wrapper";
import MediaWrapper from "@/components/contentful/media-wrapper/media-wrapper";
import VideoMediaWrapper from "@/components/contentful/video-wrapper/video-wrapper";
import TopicDefinitionWrapper from "@/components/contentful/topic-definition/topic-definition-wrapper";
import CodeSnippetWrapper from "../code-snippet-wrapper/code-snippet-wrapper";
import SongSmartLink from "../song-smart-link/song-smart-link";

import MusicCardWrapper from "@/components/contentful/music-card-wrapper/music-card-wrapper";

import InlinePersonWrapper from "@/components/contentful/person/inline-person-wrapper";

export const componentMap: any = {
    heroBanner: HeroBannerWrapper,
    videoWrapper: VideoMediaWrapper,
    imageWrapper: MediaWrapper,
    person: InlinePersonWrapper,
    topicDefinition: TopicDefinitionWrapper,
    musicCard: MusicCardWrapper,

    richContentBlock: RichContentBlockWrapper,
    cta: CtaWrapper,

    pexelsImageWrapper: PexelsMediaWrapper,
    codeSnippet: CodeSnippetWrapper,
    song: SongSmartLink,
};
